export const navConfig = {
  // memberCenter: {name: '會員中心', value: 'memberCenter'},
  appointment: { name: '預約中心', value: 'appointment' },
  couponRecord: { name: '我的使用券', value: 'couponRecord' },
  memberGame: { name: '強檔活動', value: 'memberGame' },
  ecommerce: { name: '電商購物', value: 'ecommerce' },
  // ecommerceOrder: { name: "訂單查詢", value: "ecommerceOrder" },
  classTicketRecord: { name: '我的堂票', value: 'classTicketRecord' },
  walletRecord: { name: '儲值金紀錄', value: 'walletRecord' },
  appointmentReservation: { name: '預約紀錄', value: 'appointmentReservation' },
  shopAllianceActivity: { name: '聯盟優惠', value: 'shopAllianceActivity' },
  salesRecord: { name: '消費累積', value: 'salesRecord' },
  memberGameRecord: { name: '抽獎紀錄', value: 'memberGameRecord' },
  memberProfile: { name: '基本資料', value: 'memberProfile' },
  memberHistory: { name: '服務歷程', value: 'memberHistory' },
  contactCustomer: { name: '聯繫客服', value: 'contactCustomer' },
  otherLink: { name: '其他連結', value: 'otherLink' },
  memberStore: { name: '會員商城', value: 'memberStore' },
  memberStoreOrder: { name: '商城訂單', value: 'memberStoreOrder' },
  posPalMemberWallet: { name: '會員餘額', value: 'posPalMemberWallet' },
  posPalMemberTransaction: { name: '消費紀錄', value: 'posPalMemberTransaction' },
  posPalMemberPassProduct: { name: '次卡紀錄', value: 'posPalMemberPassProduct' },
  referralActivity: { name: '邀請好友', value: 'referralActivity' },
  subscriptionRecord: { name: '訂閱紀錄', value: 'subscriptionRecord' },
  rewardDistribution: { name: '獎勵發放活動', value: 'rewardDistribution' },
  punchCardRecord: { name: '我的次卡', value: 'punchCardRecord' },
  pointCardRecord: { name: '我的點數卡', value: 'pointCardRecord' },
  entryControlRecord: { name: '進出場紀錄', value: 'entryControlRecord' },
  qrCodeScan: { name: '掃描條碼', value: 'qrCodeScan' },
}

export const functionMenuConfig = {
  walletBalance: { name: '儲值金顯示', value: 'walletBalance' },
  couponRecord: { name: '使用券顯示', value: 'couponRecord' },
  bonusPointsBalance: { name: '紅利點數顯示', value: 'bonusPointsBalance' },
  // ecommerceOrder: { name: "電商訂單查詢", value: "ecommerceOrder" },
  appointmentReservation: { name: '預約紀錄', value: 'appointmentReservation' },
  classTicketRecord: { name: '我的堂票', value: 'classTicketRecord' },
  salesRecord: { name: '消費累積', value: 'salesRecord' },
  shopAllianceActivity: { name: '聯盟優惠', value: 'shopAllianceActivity' },
  memberProfile: { name: '會員基本資料', value: 'memberProfile' },
  walletRecord: { name: '儲值金紀錄', value: 'walletRecord' },
  contactCustomer: { name: '聯繫客服', value: 'contactCustomer' },
  memberGameRecord: { name: '參加抽獎紀錄', value: 'memberGameRecord' },
  memberHistory: { name: '我的服務歷程', value: 'memberHistory' },
  rewardDistribution: { name: '獎勵發放活動', value: 'rewardDistribution' },
  entryControlRecord: { name: '進出場紀錄', value: 'entryControlRecord' },
  qrCodeScan: { name: '掃描條碼', value: 'qrCodeScan' },
}

export const customerServiceConfig = {
  lineId: { name: 'LINE', value: 'lineId' },
  phone: { name: '電話號碼', value: 'phone' },
  email: { name: '電子信箱', value: 'email' },
  url: { name: '網址', value: 'url' },
}

export const navFooterErrorCode = {
  EcommerceConfigNotFound: { msg: '尚未設置電商連結' },
  ShopInfoContactCustomerNotFound: {
    msg: '請先至上方設定聯繫客服連結',
  },
}

export const systemMemberCenterColorThemes = [
  'classic',
  'peaceful',
  'lively',
  'nature',
  'fresh',
  'simple',
]

export const systemMemberCenterColorThemesTemplate = [
  { name: 'classic', id: '@system-classic', Creator: { name: '系統' }, isSystem: true, displayName: 'Classic', primaryColor: '#31405f', secondaryColor: '#B69671' },
  { name: 'peaceful', id: '@system-peaceful', Creator: { name: '系統' }, isSystem: true, displayName: 'Peaceful', primaryColor: '#BDA28A', secondaryColor: '#AA7A41' },
  { name: 'lively', id: '@system-lively', Creator: { name: '系統' }, isSystem: true, displayName: 'Lively', primaryColor: '#FE9F6A', secondaryColor: '#EC7735' },
  { name: 'nature', id: '@system-nature', Creator: { name: '系統' }, isSystem: true, displayName: 'Nature', primaryColor: '#96BF95', secondaryColor: '#62BC81' },
  { name: 'fresh', id: '@system-fresh', Creator: { name: '系統' }, isSystem: true, displayName: 'Fresh', primaryColor: '#8EA2D3', secondaryColor: '#696EEA' },
  // { name: 'simple', id: '@system-simple', Creator: { name: '系統' }, isSystem: true, displayName: 'Simple', primaryColor: '#A35BFF', secondaryColor: '#43F374' },
]

export const memberCenterQRCodeSourceConfig = {
  weiby: { label: '微碧', value: 'weiby', featureKey: 'admin.shopCustomModule.updateWeibyClient' },
  twroct: { label: '飲食共和國', value: 'twroct', featureKey: 'admin.shopCustomModule.updateTwroctClient' },
  drfoot: { label: '凌越', value: 'drfoot', featureKey: 'admin.shopCustomModule.updateDrFootClient' },
  dudoo: { label: '肚肚', value: 'dudoo', featureKey: 'admin.shopCustomModule.updateDudooClient' },
  pospal: { label: '瑞乘', value: 'pospal', featureKey: 'admin.shopCustomModule.updatePosPal' },
}
